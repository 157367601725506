import { isSSR } from '@helpers/ssr';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
import { muiCache } from '@utils/ssr/emotionCache';
import { ThemeProvider } from '@vivino/js-react-common-ui';
import {
  AppContextProps,
  AppProvider,
  ExperimentAttributesProvider,
  I18NProvider,
} from '@vivino/js-web-common';
import React, { ReactNode } from 'react';

import SnackBarProvider from '@components/SnackBarProvider/SnackBarProvider';

interface PageProps {
  children: ReactNode;
  latest?: boolean;
  translations?: Record<string, string>;
}

const windowToAppContext = (): AppContextProps => {
  return {
    __PRELOADED_ENV__: globalThis.__PRELOADED_ENV__,
    __PRELOADED_COUNTRY_CODE__: globalThis.__PRELOADED_COUNTRY_CODE__,
    __PRELOADED_STATE_CODE__: globalThis.__PRELOADED_STATE_CODE__,
    __PRELOADED_LOCALE__: globalThis.__PRELOADED_LOCALE__,
    __PRELOADED_SIGNED_IN_USER__: globalThis.__PRELOADED_SIGNED_IN_USER__,
    __PRELOADED_STATE__: globalThis.__PRELOADED_STATE__,
    __PRELOADED_SPLIT_CLIENT_KEY__: globalThis.__PRELOADED_SPLIT_CLIENT_KEY__,
    __PRELOADED_USER_ACCESSIBILITY_ENABLED__: globalThis.__PRELOADED_USER_ACCESSIBILITY_ENABLED__,
    __PRELOADED_CAMPAIGN_USER_ID__: globalThis.__PRELOADED_CAMPAIGN_USER_ID__,
    __PRELOADED_ANONYMOUS_TRACKING_ID__: globalThis.__PRELOADED_ANONYMOUS_TRACKING_ID__,
    __PRELOADED_AB_TESTS__: globalThis.__PRELOADED_AB_TESTS__,
    __PRELOADED_SMOKE_TEST__: globalThis.__PRELOADED_SMOKE_TEST__,
    __PRELOADED_TRACKING_SCREEN_NAME__: globalThis.__PRELOADED_TRACKING_SCREEN_NAME__,
    __PRELOADED_IS_BOT_REQUEST__: globalThis.__PRELOADED_IS_BOT_REQUEST__,
    __VIVINO_GLOBALS__: globalThis.__VIVINO_GLOBALS__,
    __PRELOADED_USER_NOTIFICATION__: globalThis.__PRELOADED_USER_NOTIFICATION__,
    __PRELOADED_SHIPPING_ZIP_CODE__: globalThis.__PRELOADED_SHIPPING_ZIP_CODE__,
    __PRELOADED_SHIPPING_ZIP_PREFILL__: globalThis.__PRELOADED_SHIPPING_ZIP_PREFILL__,
    __WEB_API_HOST__: globalThis.__WEB_API_HOST__,
    vivinoCacheKey: globalThis.vivinoCacheKey,
    locale: globalThis.locale,
    menuData: globalThis.menuData,
    userCellars: globalThis.__PRELOADED_USER_CELLARS__,
  };
};

/*
 * The Page component can be used for adding providers
 * that needs to be available for all components.
 * For instance it adds the ThemeProvider, which allows us to define the theme for Common UI, which
 * is necessary to get the correct look of components consumed through the Common UI.
 * It should be the top most component of any ReactDOM.render.
 */

const Page = ({ children, ...props }: PageProps) => {
  return (
    <ErrorBoundary>
      <AppProvider value={!isSSR ? windowToAppContext() : ({} as any)}>
        <I18NProvider localePack={props?.translations || globalThis.localePack}>
          <ThemeProvider cache={muiCache}>
            <SnackBarProvider>
              <ExperimentAttributesProvider>{children}</ExperimentAttributesProvider>
            </SnackBarProvider>
          </ThemeProvider>
        </I18NProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default Page;
