import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
import { Loader } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { Suspense, useState } from 'react';
import Sentinel from 'vivino-ui/atoms/Sentinel';

import styles from './dynamicModuleLoader.scss';

const PlaceHolder = () => (
  <div className={styles.placeHolder}>
    <Loader />
  </div>
);
const DynamicModuleLoader = ({
  id,
  children,
  skeleton = <PlaceHolder />,
  scrollDelay = 1000,
  onIntersect,
}) => {
  const [inView, setInView] = useState(false);

  if (inView) {
    return (
      <Suspense fallback={skeleton}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Suspense>
    );
  }

  const handleIntersect = () => {
    setInView(true);

    /*
     * By simply scrolling to this element, position may not be correct
     * because components prior to this component gets lazy-loaded
     * and this component gets displaced further down after they get loaded.
     * So wait for previous components to load and then scroll again
     */
    if (id && location.hash === `#${id}`) {
      setTimeout(() => {
        const elem = document.getElementById(id);
        if (elem) {
          elem.scrollIntoView({
            inline: 'start',
          });
        }
      }, scrollDelay);
    }

    onIntersect?.();
  };

  /*
    The intention here is to return a box with a 200px height.
    That will be the view port. The reason we have two <Sentinel>s
    is because we want to be able to scroll up too and be able to trigger the Sentinel.
    This will prevent the DynamicLoaders from stacking up againtst each other so easily
  */

  return (
    <div className={styles.sentinel}>
      <Sentinel id={id} onIntersect={handleIntersect} />
      <Sentinel onIntersect={handleIntersect} />
    </div>
  );
};

DynamicModuleLoader.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any.isRequired,
  scrollDelay: PropTypes.number,
  skeleton: PropTypes.node,
  onIntersect: PropTypes.func,
};

export default DynamicModuleLoader;
