import * as Sentry from '@sentry/react';
import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactElement;
  additionalInfo?: Record<string, unknown>;
}

export default function ErrorBoundary({ children, fallback, additionalInfo }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={fallback}
      beforeCapture={(scope) => {
        additionalInfo &&
          Object.keys(additionalInfo).forEach((key) => {
            scope.setExtra(key, additionalInfo[key]);
          });
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
